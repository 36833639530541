// const path = `/OperPlatformAppServer/${process.env.VUE_APP_QMS_ENV}/proxy`;
const mainAPI = {
  key: 'mainAPI',
  list: [
    {
      name: 'queryListLangeByCode',
      url: '/lang/conf/queryListLangeByCode/{code}',
      method: 'GET'
    },
    {
      name:'queryImageListByKey',
      url:'/image/conf/queryImageListByKey/{key}',
      method: 'GET'
    },
    {
      name:"getNewsList",
      url:"/home/page/news/queryByPage?pageNum={pageNum}&pageSize={pageSize}&textType={textType}&textTitle={textTitle}",
      method: 'GET'
    },
    {
      name:"getDicts",
      url:"/system/dict/data/type/{type}",
      method: 'GET'
    },
    {
      name:"findSpecAll",
      url:"/product/spec/findSpecAll",
      method: 'GET'
    },
    {
      name:"getSpec",
      url:"/product/spec/{pkId}",
      method: 'GET'
    },
    {
      name:"productList",
      url:"/product/list",
      method: 'POST'
    },
    {
      name:"jobQueryByPage",
      url:"/join/us/queryByPage?pageNum={pageNum}&pageSize={pageSize}&workLocation={workLocation}&positionCategory={positionCategory}&positionName={positionName}",
      method: 'GET'
    },
    {
      name:"supplierList",
      url:"/supplier/list?pageNum={pageNum}&pageSize={pageSize}",
      method: 'GET'
    },
    {
      name:"queryWorkLocation",
      url:"/join/us/queryWorkLocation",
      method: 'GET'
    },
    {
      name:"queryPositionCategory",
      url:"/join/us/queryPositionCategory",
      method: 'GET'
    },
    {
      name:"addApply",
      url:"/job/apply",
      method:"POST"
    },
    {
      name:"addCollect",
      url:"/product/collect",
      method:"POST"
    },
    {
      name:"getProduct",
      url:"/product/{pkId}",
      method:"GET"
    },
    {
      name:"queryPosition",
      url:"/join/us/queryPosition/{pkId}",
      method:"GET"

    },
    {
      name:"getSupplierDetail",
      url:"/supplier/{pkId}",
      method:"GET"
    },
    {
      name:"getNewDetails",
      url:"/home/page/news/{pkId}",
      method:"GET"
    },
    {
      name:"queryAddrConfig",
      url:"/addr/config?pageNum={pageNum}&pageSize={pageSize}",
      method: 'GET'
    },
    {
      name:"selectPageList",
      url:"/catalogue/config/selectPageList",
      method: 'GET'
    },
    {
      name:"seoKekWords",
      url:"/system/config/configKey/sys.seo.keywords?route={route}",
      method: 'GET'
    },

//     GET
// ​/addr​/config
// 查询列表


// ​/supplier​/{pkId}

    // /join/us/queryPositionCategory
    // //登陆验证
    // {
    //   name: 'smsLogin',
    //   url: '/auth/smsLogin',
    //   method: 'POST'
    // },
    // //获取当天的未结束的比赛
    // {
    //   name: 'getVotingList',
    //   url: '/system/activity/h5/list',
    //   method: 'GET'
    // },
    //获取当前人绑定数量
    // {
    //   name: 'getBindCount',
    //   url: '/system/bind/h5/count',
    //   method: 'GET'
    // },
    // 绑定二维码
    // {
    //   name: 'bindQrCode',
    //   url: '/system/bind/h5/{qrcode}',
    //   method: 'GET'
    // },
    // 投票接口
    // {
    //     name: 'h5Vote',
    //     url: '/system/activity/h5/vote',
    //     method: 'POST'
    //   },
    //   /activity/h5/vote/list
       // 获取投票记录
    // {
    //     name: 'voteList',
    //     url: '/system/activity/h5/vote/list',
    //     method: 'GET'
    //   },
      //所有比赛
      // {
      //   name: 'getActivityItemList',
      //   url: '/system/activity/h5/item/list',
      //   method: 'GET'
      // },
      //奖品
      // {
      //   name: 'getAward',
      //   url: '/system/activity/h5/award/list?activityId={activityId}&activityItemId={activityItemId}',
      //   method: 'GET'
      // },
      //我的中奖
      // {
      //   name: 'getWinList',
      //   url: '/system/activity/h5/win/list?pageNum={pageNum}&pageSize={pageSize}',
      //   method: 'GET'
      // },
      //公共
      // {
      //   name: 'getNotice',
      //   url: '/system/notice/today',
      //   method: 'GET'
      // },

       //添加评论
      //  {
      //   name: 'addComment',
      //   url: '/system/comment/add',
      //   method: 'POST'
      // },

       //评论区
      //  {
      //   name: 'commentList',
      //   url: '/system/comment/list?pageNum={pageNum}&pageSize={pageSize}',
      //   method: 'GET'
      // },

      //收货地址
      // {
      //   name: 'addAddress',
      //   url: '/system/address/add',
      //   method: 'POST'
      // },
      // {
      //   name: 'getAddress',
      //   url: '/system/address/get',
      //   method: 'GET'
      // },
      // {
      //   name: 'editAddress',
      //   url: '/system/address/edit',
      //   method: 'POST'
      // },


      // {
      //   name: 'getNotice',
      //   url: '/system/h5/comment/upload',
      //   method: 'GET'
      // },
      // localhost:9201/activity/h5/win/list

      
  ]
};

export default mainAPI;



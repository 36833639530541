import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router';

import api from "@/api/api";
import IndexPage from '@/views/index/index.vue'
import MbPage from '@/views/index/index-mb.vue'
import ArticlePage from '@/views/article/index.vue'
import AboutMePage from '@/views/about-me/index.vue'
import ProductPage from '@/views/product/index.vue'
import ProductDetailPage from '@/views/product-detail/index.vue'
import WorkmanshipRgPage from "@/views/workmanshipRg/index.vue"
import WorkmanshipPcPage from "@/views/workmanshipPc/index.vue"
import WorkmanshipGzPage from "@/views/workmanshipGz/index.vue"
import WorkmanshipPgPage from "@/views/workmanshipPg/index.vue"
import WorkmanshipGsgPage from "@/views/workmanshipGsg/index.vue"
import InnovatePage from "@/views/innovate/index.vue"
import LatestNewsPage from "@/views/latest-news/index.vue"
import ContactMePage from "@/views/contact-me/index.vue"
import PositionDetailPage from "@/views/position-detail/index.vue"
import ProductInfoPage from "@/views/product-info/index.vue"
import SupplierPage from  "@/views/supplier/index.vue"
import SupplierDetailPage from  "@/views/supplier-detail/index.vue"
import AddressPage from "@/views/address/index.vue"
import ProductUsePage from "@/views/product-use/index.vue"

import demo from "@/views/demo.vue"
// import BasePage from '@/views/base-page/base-page.vue'
import PitchInPage from '@/views/pitch-in-page.vue'
import ActivityRules from '@/views/activity-rules.vue'
import VotingRecord from '@/views/voting-record.vue'
import VotingPage from '@/views/voting-page.vue'
import ActivityTime from '@/views/activity-time.vue'
import WinNotice from '@/views/win-notice.vue'
import {getCookie} from '@/utils/index'
// import Home from '../views/Home.vue'
// import BaseHomePage from

// import BaseLayout from '@/views/base-layout/base-layout.vue';
// import HomePage from '@/views/home-page/home-page.vue';

// import oldProject from '@/pages/apps/old-project/index.vue';
// import homePage from '@/pages/apps/home-page/index.vue';
// import basicData from '@/pages/apps/basic-data/index.vue';
// import planManage from '@/pages/apps/plan-manage/index.vue';
// import enginManage from '@/pages/apps/engin-manage/index.vue';
// import appCenter from '@/pages/apps/app-center/index.vue';
// import reportCenter from '@/pages/apps/report-center/index.vue';
// import platformManage from '@/pages/apps/platform-manage/index.vue';

// import child1 from '../pages/apps/child1.vue';

const routes: Array<RouteRecordRaw> = [
 
  {
    path: '/',
    name: 'index',
    component: IndexPage,
    meta:{title:"首页",keepAlive:true}
  },
  {
    path:'/mb',
    name:'mb',
    component:MbPage,
  },
  {
    path:'/productUse',
    name:'productUse',
    component:ProductUsePage,
    meta:{title:"产品应用"}
  },
  {
    path:"/article",
    name:"article",
    component:ArticlePage,
    meta:{title:"文章"}
  },
  {
    path: '/aboutMe',
    name: 'aboutMe',
    component: AboutMePage,
    meta:{title:"关于我们"}
  },
  {
    path: '/productInfo',
    name: 'productInfo',
    component: ProductInfoPage,
    meta:{title:"产品信息"}
  },

  
  {
    path: '/product',
    name: 'product',
    component: ProductPage,
    meta:{title:"群欣产品"}
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: ProductDetailPage,
    meta:{title:"群欣产品"}
  },

  {
    path: '/workmanshipRg',
    name: 'workmanshipRg',
    component: WorkmanshipRgPage,
    meta:{title:"工艺流程"}
  },

  {
    path: '/workmanshipPc',
    name: 'workmanshipPc',
    component: WorkmanshipPcPage,
    meta:{title:"工艺流程"}
  },
  {
    path: '/workmanshipGz',
    name: 'workmanshipGz',
    component: WorkmanshipGzPage,
    meta:{title:"工艺流程"}
  },

  {
    path: '/workmanshipGsg',
    name: 'workmanshipGsg',
    component: WorkmanshipGsgPage,
    meta:{title:"工艺流程"}
  },
  

  {
    path: '/workmanshipPg',
    name: 'workmanshipPg',
    component: WorkmanshipPgPage,
    meta:{title:"工艺流程"}
  },
  {
    path: '/innovate',
    name: 'innovate',
    component: InnovatePage,
    meta:{title:"研发创新"}
  },
  {
    path: '/latestNews',
    name: 'latestNews',
    component: LatestNewsPage,
    meta:{title:"最新消息"}
  },
  {
    path: '/contactMe',
    name: 'contactMe',
    component: ContactMePage,
    meta:{title:"联系我们"}
  },
  {
    path: '/positionDetail',
    name: 'positionDetail',
    component: PositionDetailPage,
    meta:{title:"岗位详情"}
  },
  {
    path:"/supplier",
    name: 'supplier',
    component: SupplierPage,
    meta:{title:"供应商列表"}
  },
  {
    path:"/supplierDetail",
    name: 'supplierDetail',
    component: SupplierDetailPage,
    meta:{title:"供应商详情"}
  },
  {
    path:"/address",
    name: 'address',
    component: AddressPage,
    meta:{title:"公司地点"}
  },

  
  {
    path: '/demo',
    name: 'demo',
    component: demo,
    meta:{title:"demo"}
  },
  
  


  
  // {
  //   path: '/activity-rules',
  //   name: 'ActivityRules',
  //   component: ActivityRules,
  //   meta:{title:"活动规则"}
  // },
  // {
  //   path: '/voting-record',
  //   name: 'VotingRecord',
  //   component: VotingRecord,
  //   meta:{title:"投票记录"}
  // },
  // {
  //   path: '/activity-time',
  //   name: 'ActivityTime',
  //   component: ActivityTime,
  //   meta:{title:"我的中奖"}
  // },
  // {
  //   path: '/win-notice',
  //   name: 'WinNotice',
  //   component: WinNotice,
  //   meta:{title:"中奖公告"}
  // },
  // {
  //   path: '/',
  //   name: 'VotingPage',
  //   component: VotingPage,
  //   meta:{title:"扫码投票"}
  // }
];




const router = createRouter({
    // history: createWebHashHistory(),
  history: createWebHistory(process.env.NODE_ENV === 'production'?"":""),
  routes,
  //增加这个配置
//   scrollBehavior(to, from, savedPosition) {
//     console.log(savedPosition)
//     if(savedPosition) {
//         return savedPosition
//     } else {
//         return {
//             x: 0,
//             y: 0
//         }
//     }
// }
});

// router.beforeEach((to, from, next) => {
//   console.log('基座路由守卫-----', to, from, next);
//   next();
// });


router.beforeEach((to, from, next) => {

  //判断去哪里
  // let hasCookie = getCookie('access_token')
  /* 路由发生变化修改页面title */
  // 路由跳转后，让页面回到顶部
  // document.body.scrollTop = 0; 
  // document.documentElement.scrollTop = 0; 
  // window.pageYOffset = 0;
  console.log(to)
  console.log(to)
    api.mainAPI.seoKekWords({
      pathParams:{
        "route":encodeURIComponent(to.fullPath)
      }
    }).then((res:any)=>{
      let {code,data} = res || {}
      if(code != 200){
        return
      }
      let {keywords,description} = data || {}

    // updateMetaTags("keywords","000000")
    updateMetaTags(keywords,description)
  })
  if (to.meta.title) {
    let title :any= to.meta.title
    document.title = title
  }
  // console.log(to.path)

  let toPath = to.path
  if(toPath == "/"){
    // if(hasMobile()){
    //   next({path:'/mb'});
    // }else{
    //   next()
    // }
    next()
  }else{
    next()
  }

    //有登陆信息的 去首页
    // if(hasCookie || (!hasCookie && toPath == "/BasePage")){
    //   next()
    //   // router.push({
    //   //   name:"VotingPage",
    //   //   // query:query
    //   // })
    // }else{
    //   // state.isLoading = false;
    //   // console.log('未登陆，重定向');
    //   // console.log(to)
    //   next({path:'/BasePage',query:to.query});
    //   // router.push({
    //   //   name:"BasePage",
    //   //   query:route.query
    //   // })
  
    // }
 
})

export default router;


//  判断是否为移动端
const  hasMobile =()=> {
  let isMobile = false;
  if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      // console.log('移动端');
      isMobile = true;
  }
  if (document.body.clientWidth < 800) {
      isMobile = true;
  }
  return isMobile
}


const  updateMetaTags = (k:any,d:any)=> {
  const head = document.getElementsByTagName('head')[0];
  // 移除已有的 description meta 元素
  const existingMetaDescriptions = head.querySelectorAll('meta[name="description"]');
  existingMetaDescriptions.forEach(meta => head.removeChild(meta));
  
  // 添加新的 description meta 元素
  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', k);
  metaDescription.setAttribute('content', d);
  head.appendChild(metaDescription);
  
  // 更新 title
  // document.title = this.title;
}